<template>
    <div v-if="translates && translates[langUrl]" class="img-loader">
        <div
            class="img-loader__uploader"
            :class="{ 'img-loader__uploader--error': errorMessages, 'img-loader__uploader--small': small }"
            @click="getImage">
            <img
                v-if="srcUrl && !imgShow"
                class="image"
                :src="srcUrl"
                alt="" />
            <img
                v-if="imgShow && !srcUrl"
                class="image"
                alt="" />

            <template v-if="!srcUrl && !imgShow">
                <v-icon class="mr-1" color="#FF385C">
                    mdi-plus
                </v-icon>
                <div class="img-loader__uploader-description" v-html="translates[langUrl].text[lang]"></div>
            </template>

            <input
                ref="file"
                type="file"
                class="display-none"
                accept="image/png, image/jpeg"
                @change="_onFileChange" />
        </div>

        <div class="img-loader__error-messages">{{ errorMessages }}</div>
        {{ message }}
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    export default {
        name: 'ImgLoader',
        components: {},
        model: {
            prop: 'src',
            event: 'change',
        },
        props: {
            src: [String, File],
            errorMessages: {
                type: String,
                default: ''
            },
            small: {
                type: Boolean,
                default: false
            },
            text: {
                type: String,
            },
            quality: {
                type: String,
                default: 'low'
            }
        },
        data: () => ({
            uploadDir: process.env.VUE_APP_UPLOAD_DIR,
            message: '',
            imgShow: false,
            langUrl: 'ImgLoader',
        }),
        computed: {
            ...mapState('settings', ['lang']),
            ...mapState('translates', {
                translates: state => state.entities
            }),
            srcUrl() {
                return this.src
                    ? this.src.constructor.name === 'File'
                        ? URL.createObjectURL(this.src)
                        : process.env.VUE_APP_UPLOAD_DIR +
                            '/' +
                            this.src.split('\\').join('/')
                    : '';
            },
        },
        methods: {
            getImage() {
                this.$refs.file.click();
            },
            clear() {
                this.$emit('change', '');
            },
            async _onFileChange(e) {
                let maxSize = 800;
                switch (this.quality) {
                case 'low':
                    maxSize = 800;
                    break;
                case 'medium':
                    maxSize = 1400;
                    break;
                case 'high':
                    maxSize = 2000;
                    break;
                }
                const filePath = await store.dispatch('files/upload', { file: e.target.files[0], maxSize });
                this.$emit('change', filePath);
            },
        },
    };
</script>

<style lang="scss">
.img-loader {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    margin-bottom: 16px;
}

.img-loader__uploader {
    width: 175px;
    height: 166px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed #dbdae0;
    box-sizing: border-box;
    border-radius: 10px;
    background: #fff !important;
    box-shadow: rgba(30, 28, 46, 0.03);
    cursor: pointer;
    overflow: hidden;
    transition: border-color 0.2s;
    &--small {
        height: 48px;
        flex-direction: row;
    }
    &--error {
        border: 1px solid #ff5252;

        &:hover {
            border-color: #ff5252;
        }
    }

    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        pointer-events: none;
    }
    input {
        display: none;
    }
    &:hover {
        border-color: #333;
    }
}

.img-loader__error-messages {
    color: #ff5252;
    font-size: 12px;
    // margin-bottom: 25px;
    width: 100%;
    // padding: 0 12px;
}

.img-loader__uploader-description {
    // width: 50%;
    text-align: center;
    color: #a7a6af;
}

.img-loader__hint {
    position: absolute;
    right: -360px;
    top: 143px;
}

.img-loader__clear {
    position: absolute;
    top: 14px;
    right: 14px;
}
</style>
