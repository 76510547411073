<template>
    <div class="configurator__map">
        <div class="configurator__map__container">
            <div class="configurator__map__container__name">{{ text.find }}</div>
            <div class="configurator__map__point">
                <BaseTextField
                    v-model="mapPoint"
                    class="configurator__map__point__input"
                    :label="text.find"
                    @keyup.enter="getPoints"
                />
                <BaseButton 
                    class="configurator__map__point__button button-fill"
                    @click="getPoints">{{ text.findButton }}</BaseButton>
            </div>
        </div>
        <div class="configurator__map__container" v-if="mapPoints.length">
            <div class="configurator__map__container__name">{{ text.addPoints }}</div>
            <div class="configurator__map__list">
                <template v-for="(point, i) in mapPoints">
                    <div :key="`point-${i}`" class="configurator__map__list__line">
                        <div class="configurator__map__list__line__direction">
                            {{ point.title }}
                        </div>
                        <img
                            src="../../../assets/Add2.svg" 
                            @click="setPoint(point)"
                        />
                    </div>
                </template>
            </div>
        </div>
        <div class="configurator__map__separate"></div>
        <div class="configurator__map__container">
            <div class="configurator__map__container__name">{{ text.points }}</div>
            <div class="configurator__map__list">
                <Draggable v-model="trip.direction" tag="div">
                    <div v-for="(direct, i) in trip.direction" :key="`direction-${i}`" class="configurator__map__list__line">
                        <div class="configurator__map__list__line__direction">
                            <img
                                src="../../../assets/Move.svg"
                            />
                            <template v-if="direct.title">
                                {{ direct.title }}
                            </template>
                            <template v-else>
                                <template v-if="direct.country">{{ direct.country.title }}</template>,
                                <template v-if="direct.state">{{ direct.state.title }},</template>
                                <template v-if="direct.city">{{ direct.city.title }}</template>
                            </template>
                        </div>
                        <img
                            src="../../../assets/Delete2.svg"
                            @click="removeTripValue({ prop_1: 'direction', value: i })"
                        />
                    </div>
                </Draggable>
            </div>
        </div>
        <div class="configurator__map__container">
            <div class="configurator__map__container__name">{{ text.map }}</div>
            <div class="configurator__map__map__container" v-if="trip.direction && coords">
                <yandexMap 
                    ref="ymap"
                    class="configurator__map__map"
                    :coords="coords"
                    :controls="['zoomControl']"
                    :zoom="zoom"
                >
                    <template v-for="(direct, i) in trip.direction">
                        <ymapMarker 
                            :key="i"
                            :marker-id="i"
                            marker-type="Placemark"
                            :coords="direct.coordinates"
                        />
                    </template>
                    <ymapMarker
                        v-if="trip.direction.length > 1"
                        marker-id="1"
                        marker-type="Polyline"
                        :coords="polyline"
                    />
                </yandexMap>
            </div>
        </div>
    </div>
</template>



<script>
    import axios from 'axios';
    import Draggable from 'vuedraggable';
    import BaseButton from '../BaseButton';
    import BaseTextField from '../BaseTextField';
    import { yandexMap, ymapMarker } from 'vue-yandex-maps';

    import { translit } from '../../../helpers';

    export default {
        name: 'Map',
        components: {
            Draggable,
            BaseButton,
            BaseTextField,
            yandexMap, 
            ymapMarker
        },
        props: {
            trip: { 
                type: Object,
                default: () => {}
            },
            text: {
                type: Object,
                default: () => {}
            }
        },
        data: () => ({
            mapPoint: '',
            mapPoints: [55.749451, 37.542824],
            coords: [],
            zoom: 5,
        }),
        computed: {
            polyline() {
                return this.trip.direction.filter(item => item.coordinates).map(item => item.coordinates);
            },
        },
        async mounted() {
            this.coords = [
                this.trip.direction[0] ? this.trip.direction.reduce((sum, item) => {
                    return sum + +item.coordinates[0]
                }, 0) / this.trip.direction.length : 55.749451,
                this.trip.direction[0] ? this.trip.direction.reduce((sum, item) => {
                    return sum + +item.coordinates[1]
                }, 0) / this.trip.direction.length : 37.542824
            ];
        },
        methods: {
            async getPoints() {
                this.mapPoints = [];
                if(this.mapPoint && this.mapPoint.length) {
                    const options = [
                        'apikey=a2ca1bc1-014c-4302-afa5-8b7be4c77dd6',
                        'format=json',
                        'results=10',
                        `geocode=${this.mapPoint}`
                    ];
                    if(process.env.VUE_APP_SITE_LANGUAGE === 'en') {
                        options.push('lang=en-US');
                    }
                    const { data } = await axios.get(`https://geocode-maps.yandex.ru/1.x/?${ options.join('&') }`);
                    
                    this.mapPoints = data.response.GeoObjectCollection.featureMember.map(point => {
                        const coords = point.GeoObject.Point.pos.split(' ');
                        const components = point.GeoObject.metaDataProperty.GeocoderMetaData.Address.Components;
                        let country = null, state = null, city = null;
                        for(const component of components) {
                            switch(component.kind) {
                                case 'country':
                                    country = {
                                        title: component.name,
                                        code: translit(component.name)
                                    }
                                    break;
                                case 'province':
                                    state = {
                                        title: component.name,
                                        code: translit(component.name)
                                    }
                                    break;
                                case 'locality':
                                    city = {
                                        title: component.name,
                                        code: translit(component.name)
                                    }
                                    break;
                            }
                        }
                        const result = {
                            title: point.GeoObject.metaDataProperty.GeocoderMetaData.text,
                            coordinates: [coords[1], coords[0]],
                            components,
                            country,
                            state,
                            city
                        };
                        return result;
                    }) || [];
                }
            },
            setPoint(point) {
                this.trip.direction.push(point);
                this.mapPoints = [];
                this.coords = point.coordinates;
                this.zoom = 10;
            },
            addTripType() {
                let arr = this.trip.additionalTypes;
                if(!arr) arr = [];
                arr.push('');
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'additionalTypes', value: arr });
            },
            removeTripValue({ prop_1, value }) {
                let arr = this.trip[prop_1];
                arr.splice( value, 1 );
                store.commit('trips/SET_ENTITY_VALUE', { prop_1: 'prop_1', value: arr });
            }
        }
    }
</script>

<style lang="scss">
.configurator__map {
    &__container {
        display: flex;
        width: 100%;
        min-height: 56px;
        margin-bottom: 20px;

        &__name {
            display: none;
            @media all and (min-width: 768px) {
                display: flex;
                min-width: 185px;
                max-width: 185px;
                margin-right: 40px;
                color: #65676E;
                padding-top: 18px;
            }
        }
    }

    &__separate {
        width: 100%;
        height: 1px;
        background: #EBEBEB;
    }

    &__point {
        display: flex;
        width: 100%;
        
        .base-text-field, .v-text-field--outlined.base-text-field {
            margin-bottom: 0 !important;
        }

        &__button {
            align-self: flex-start;
            height: 58px !important;
            margin-left: 10px;
        }
    }

    &__list {
        width: 100%;
        padding-top: 10px;

        &__line {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            font-family: Inter;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 140%;
            letter-spacing: -0.02em;
            color: #273155;
            padding: 10px 0;

            &__direction {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;

                > img {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;
                    cursor: pointer;
                }
            }

            > img {
                cursor: pointer;
                margin-left: 10px;
            }
        }
    }

    &__map {
        width: 100%;
        height: 400px;

        &__container {
            width: 100%;
            height: 400px;
            border-radius: 10px;
            overflow: hidden;
        }
    }
}
</style>